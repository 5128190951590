.answers {
    padding: 10px;
}

.answers .answers__answer {
    margin-top: 30px;
}

.answers .answers__answer:first-child {
    margin-top: 0;
}

.answers .answers__data {
    border: solid 1px rgb(218, 218, 218);
}

.answers .answers__data.input {
    max-height: 200px;
    overflow: auto;
}

.answers .answers__data.input ul {
    list-style: none;
    margin: 0;
    padding: 5px 10px;
}

.answers .answers__data.input li {
    padding: 5px 0;
}
