.notification-display {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10000;
    padding: 10px;
}

.notification-display__item {
    cursor: pointer;
    margin-bottom: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}
