.surveys-list {
    width: 100%;
}

.surveys-list td {
    vertical-align: middle;
}

.surveys-list .comment {
    margin-top: 3px;
}
