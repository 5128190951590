.memberGroup-edit {
    margin: 20px;
}

.memberGroup-edit .tab-pane {
    padding-top: 30px;
}

.memberGroup-edit .answer {
    margin-bottom: 5px;
}

.memberGroup-edit .add-question {
    margin-bottom: 8px;
}
