html,
body {
    min-height: 100%;
    height: 100%;
}

body {
    display: flex;
    background-color: #f5f5f5;
}

#root {
    width: 100%;
}
