.survey-edit {
    margin: 20px;
}

.survey-edit .tab-pane {
    padding-top: 30px;
}

.survey-edit .answer {
    margin-bottom: 5px;
}

.survey-edit .add-question {
    margin-bottom: 8px;
}
